<template>
  <div class="vocabulary-container">
    <div class="row">
      <div class="col-12">
        <b-card class="my-0 py-0">
          <b-card-header
            class="d-flex align-items-center justify-content-between"
          >
            <h4 class="card-title">Automatic Notes: Vocabulary</h4>
            <div>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="addVocabulary()"
                :disabled="blockButton"
              >
                Add <feather-icon icon="PlusIcon" class="ml-1" />
              </b-button>
            </div>
          </b-card-header>
          <b-card-body class="my-0 py-0">
            <b-table-simple striped hover sticky-header="60vh">
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="field in fields"
                    :key="field.key"
                    :class="field.thClass"
                  >
                    {{ field.label }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in vocabulary" :key="item.phrase">
                  <!-- <b-td>
                    <div class="d-flex justify-content-center">
                      <span class="text-capitalize" v-if="!item.editable">
                        {{ underscoeToSpace(item.use_for) }}
                      </span>
                      <b-form-input
                        class="text-center"
                        v-else
                        v-model="item.use_for"
                        placeholder="Enter use for"
                      ></b-form-input>
                    </div>
                  </b-td> -->
                  <b-td
                    ><span v-if="!item.editable">
                      {{ underscoeToSpace(item.phrase) }}
                    </span>
                    <b-form-input
                      :ref="`phrase-${index}`"
                      v-else
                      autofocus
                      v-model="item.phrase"
                      placeholder="Enter phrase"
                    ></b-form-input>
                  </b-td>
                  <!-- <b-td
                    ><span v-if="!item.editable">
                      {{ item.sounds_like ? item.sounds_like : "-" }}
                    </span>
                    <b-form-input
                      v-else
                      v-model="item.sounds_like"
                      placeholder="Enter sounds like"
                    ></b-form-input>
                  </b-td>
                  <b-td
                    ><span v-if="!item.editable">
                      {{ item.ipa ? item.ipa : "-" }}
                    </span>
                    <b-form-input
                      v-else
                      v-model="item.ipa"
                      placeholder="Enter ipa"
                    ></b-form-input>
                  </b-td> -->
                  <b-td
                    ><span v-if="!item.editable">
                      {{ item.display_as ? item.display_as : "-" }}
                    </span>
                    <b-form-input
                      v-else
                      v-model="item.display_as"
                      placeholder="Enter display as"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <div class="d-flex justify-content-center">
                      <b-button
                        v-if="!item.editable"
                        size="sm"
                        @click="item.editable = !item.editable"
                        variant="outline-primary"
                      >
                        Edit
                        <!-- <feather-icon icon="EditIcon" /> -->
                      </b-button>

                      <b-button
                        v-if="item.editable"
                        size="sm"
                        variant="outline-primary"
                        @click="createOrUpdateVocabulary(item)"
                      >
                        {{ item.is_new ? "Save" : "Update" }}
                        <!-- <feather-icon icon="SaveIcon" /> -->
                      </b-button>

                      <!-- <b-form-checkbox
                        class="ml-3"
                        v-model="item.is_active"
                        name="check-button"
                        switch
                        value="1"
                        unchecked-value="0"
                      >
                      </b-form-checkbox> -->

                      <b-button
                        v-if="item.is_new == false"
                        class="ml-3"
                        size="sm"
                        variant="outline-danger"
                        @click="removeVocabulary(item)"
                      >
                        Delete
                        <!-- <feather-icon icon="TrashIcon" /> -->
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </div>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          class="my-0 py-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </div>
  </div>
</template>

<script>
import VocabularyService from "@/views/management/views/settings/views/call-transcription-vocabulary/services/index.js";
export default {
  name: "Vocabulary",
  data() {
    return {
      vocabulary: [],
      fields: [
        // { key: "use_for", label: "Use For", thClass: "w-15 text-center" },
        { key: "phrase", label: "Phrase", thClass: "w-15 text-left" },
        // { key: "sounds_like", label: "Sounds Like", thClass: "w-15 text-left" },
        // { key: "ipa", label: "IPA", thClass: "w-15 text-left" },
        { key: "display_as", label: "Display As", thClass: "w-15 text-left" },
        { key: "actions", label: "Actions", thClass: "w-15 text-center" },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      blockButton: false,
    };
  },
  mounted() {
    this.getVocabulary();
  },
  watch: {
    currentPage() {
      this.getVocabulary();
    },
  },
  methods: {
    addVocabulary() {
      this.vocabulary.unshift({
        use_for: "automatic_notes",
        phrase: "",
        sounds_like: "",
        ipa: "",
        display_as: "",
        is_active: 1,
        editable: true,
        is_new: true,
      });
      this.$nextTick(() => {
        this.$refs[`phrase-0`][0].focus();
      });
      // verify if in data exists an item with is_new = true
      this.blockButton = this.vocabulary.some((item) => item.is_new);
    },
    underscoeToSpace(text) {
      return text.replace(/_/g, " ");
    },
    async removeVocabulary(item) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        const { data } = await VocabularyService.execute("destroy", item);
        this.getVocabulary();
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          data.message
        );
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getVocabulary() {
      try {
        this.addPreloader();
        let params = {
          page: this.currentPage,
          per_page: this.perPage,
        };

        const { data } = await VocabularyService.execute("index", params);
        data.data.forEach((vocabulary) => {
          vocabulary.editable = false;
          vocabulary.is_new = false;
        });

        this.vocabulary = data.data;
        this.totalRows = data.total;
        this.currentPage = data.current_page;
        this.blockButton = false;
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    hasBlankSpaces(word) {
      return word.includes(" ");
    },
    // isSeparatedByDash(word) {
    //   return word.includes("-");
    // },
    async createOrUpdateVocabulary(item) {
      try {
        if (this.hasBlankSpaces(item.use_for)) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Phrase can't have blank spaces"
          );
          return;
        }

        // if (
        //   item.use_for === "automatic_notes" &&
        //   !this.isSeparatedByDash(item.phrase)
        // ) {
        //   this.showToast(
        //     "danger",
        //     "top-right",
        //     "Error",
        //     "XIcon",
        //     "Phrase must be separated by dash"
        //   );
        //   return;
        // }

        if (item.use_for === "automatic_notes" && !item.phrase) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Phrase is required"
          );
          return;
        }

        if (item.use_for === "automatic_notes" && !item.display_as) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Display as is required"
          );
          return;
        }

        this.addPreloader();
        let action = item.is_new ? "store" : "update";
        const { data } = await VocabularyService.execute(action, item);
        this.blockButton = false;
        item.editable = false;
        this.getVocabulary();
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          data.message
        );
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vocabulary-container {
  .card-title {
    font-size: 1.5rem;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-70 {
    width: 70% !important;
  }
}
</style>